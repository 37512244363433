import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/Home";
import Location from "./Pages/Location";
import HowTheyGrow from "./Pages/HowTheyGrow";
import Harvest from "./Pages/Harvest";
import Services from "./Pages/Services";
import "./App.css";
import InfoBar from "./components/InfoBar/InfoBar.js";
import NavBar from "./components/NavBar/NavBackground.js";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Footer from "./components/Footer/Footer";
import Quote from "./Pages/Quote";

const theme = createTheme({
  typography: {
    fontFamily: `"Petrona", serif`,
    fontSize: "inherit",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    secondary: {
      main: "#005e2b",
    },
    text: {
      main: "#FFFFFF",
      contrastText: "#005e2b",
      dark: " #000000",
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <InfoBar />
        <NavBar />

        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Routes>
          <Route path="/Location" element={<Location />} />
        </Routes>
        <Routes>
          <Route path="/HowTheyGrow" element={<HowTheyGrow />} />
        </Routes>
        <Routes>
          <Route path="/Harvest" element={<Harvest />} />
        </Routes>
        <Routes>
          <Route path="/Services" element={<Services />} />
        </Routes>
        <Routes>
          <Route path="/Quote" element={<Quote />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}
