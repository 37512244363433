import { Box, Typography } from "@mui/material";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTime from "@mui/icons-material/AccessTime";
import "../CSS/Background.css";
import "./Address.css";

export default function address() {
  return (
    <Box
      className="background"
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "stretch",
      }}
    >
      {/*---------Location box start---------*/}
      <Box
        sx={{
          margin: "16px",
          padding: "8px",
          display: "flex",
          alignItems: "center",
          boxShadow: "10",
          borderRadius: "24px",
          backgroundColor: "secondary.main",
        }}
      >
        <LocationOnIcon sx={{ fontSize: "50px", color: "text.main" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <Typography
            sx={{ color: "text.main", fontSize: "25px" }}
          ></Typography>
          <Typography sx={{ color: "text.main", fontSize: "25px" }}>
            11650 Santa Monica Blvd.
          </Typography>
          <Typography sx={{ color: "text.main", fontSize: "25px" }}>
            West Los Angeles, CA 90025
          </Typography>
        </Box>
      </Box>
      {/*---------Location box end---------*/}
      {/*---------hours box Start---------*/}
      <Box
        sx={{
          margin: "16px",
          padding: "8px",
          display: "flex",
          alignItems: "center",
          boxShadow: "10",
          borderRadius: "24px",
          backgroundColor: "secondary.main",
        }}
      >
        <AccessTime sx={{ fontSize: "50px", color: "text.main" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <Typography
            sx={{ color: "text.main", fontSize: "25px" }}
          ></Typography>
          <Typography sx={{ color: "text.main", fontSize: "25px" }}>
            Lot Hours:
          </Typography>
          <Typography sx={{ color: "text.main", fontSize: "25px" }}>
            Monday - Friday: 10AM – 9PM
          </Typography>
          <Typography sx={{ color: "text.main", fontSize: "25px" }}>
            Saturday & Sunday: 10AM – 9PM
          </Typography>
        </Box>
      </Box>
    </Box> /*---------hours box Start---------*/
  );
}
