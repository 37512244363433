import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import "../CSS/Background.css";

export default function HarvestCard() {
  return (
    <Box className="tree-card-container">
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/I0h8GH2.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "Using its powerful rotor blades, the helicopter is expertly hoisting several trees off the ground with ease."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/OyDnuED.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "A group of workers observe a helicopter lifting trees using a winch, marveling at the impressive display of machinery in action."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/zNIq41N.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "The diligent worker eagerly assists the skilled pilot in expertly securing the load of trees, showcasing the value of teamwork and cooperation."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/HhXCXhw.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "The pilot makes a deft attempt to release the line and carefully position it to secure the trees, demonstrating their exceptional flying abilities and precision."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/JOXi5vP.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "With impressive efficiency, the helicopter effortlessly lifts yet another load of heavy trees off the ground, demonstrating the remarkable power and versatility of this magnificent flying machine."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/KoRywbJ.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "Gracefully soaring through the air with its valuable cargo of trees, the helicopter showcases the unparalleled capabilities of modern aviation technology."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/Cj6C8Gg.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "A dedicated team of workers diligently transport the freshly cut trees to the designated baling area, where they will be carefully prepared for their next stage of transport and distribution."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/GC6zYeW.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "Due to their substantial weight, the transportation of these magnificent trees necessitates the combined effort of several skilled individuals working in tandem, highlighting the importance of teamwork and cooperation in the forestry industry."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/Fo8Uz6w.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "The expertly designed tree baler meticulously wraps each tree in a protective layer, ensuring that they are well-prepared for their journey to their final destination, and representing a crucial step in the process of bringing nature's bounty to consumers around the world."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/zuJGYgL.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "A bustling scene unfolds as a group of dedicated workers tirelessly tend to multiple tree balers simultaneously, each one carefully preparing the freshly cut trees for transport with precision and care, underscoring the tireless dedication and hard work that goes into the forestry industry."
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/M3IA5G7.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "We have the pleasure of introducing Andy, a dear friend and proud owner of Oregon's most exceptional Christmas tree farm!"
            }
          </Typography>
        </CardContent>
      </Card>
      <Card className="Card" sx={{ boxShadow: 5, borderRadius: "24px" }}>
        <CardMedia
          sx={{ height: "400px", paddingTop: "50.25%" }}
          image={"https://i.imgur.com/tE3ECsB.jpg"}
        />
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: "24px",
            }}
          ></Typography>
          <Typography variant="body" color="text.dark">
            {
              "It's heartening to see Andy, a seasoned veteran of the forestry industry, working side by side with his son as they tend to their lush and thriving Christmas tree farm, passing on invaluable knowledge and experience from one generation to the next."
            }
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
